import { Routes, Route, Navigate } from "react-router-dom";
import { Main } from "./main";
import Dashboard from "./dashboard/Dashboard";
import Users from "./users/Users";
import Entities from "./entities/Entities";
import LoginForm from "./login/Login";

const RoutesList = () => {
  return (
    <Routes>
      <Route>
        <Route path="/" element={<LoginForm />} />
      </Route>
      <Route element={<Main />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="users" element={<Users />} />
        <Route path="entities" element={<Entities />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
};
export default RoutesList;
