import clsx from "clsx";
interface StatusBadgeProps {
  status: "Completed" | "In-progress" | "Refused" | "Archived";
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  return (
    <div
      className={clsx("flex items-center py-1.5 px-3 rounded-lg border", {
        "border-[#64B667] bg-[#EBFFEB]": status === "Completed",
        "border-[#FBBC04] bg-[#FFF5E9]": status === "In-progress",
        "border-[#B85042] bg-[#FFF6F5]": status === "Refused",
        "border-[#FF3232] bg-[#FFDAD5]": status === "Archived",
      })}
    >
      <span
        className={clsx(
          "flex-shrink-0 w-[0.375rem] h-[0.375rem] rounded-full",
          {
            "bg-[#64B667]": status === "Completed",
            "bg-[#FBBC04]": status === "In-progress",
            "bg-[#B85042]": status === "Refused",
            "bg-[#FF3232]": status === "Archived",
          }
        )}
      ></span>
      <span className="ml-2 text-[0.875rem] font-[500] leading-[18px] text-black">
        {status}
      </span>
    </div>
  );
};

export default StatusBadge;
