import moment from "moment";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { statusOptions } from "./Filter";

export function SelectedFilters({
  filters,
  setFilters,
  hasFilters,
  setTempFilters,
}) {
  return (
    <div
      className={`${
        hasFilters ? "mx-4 md:mx-0 pb-2" : ""
      } flex items-center gap-2 overflow-auto md:overflow-hidden md:flex-wrap`}
    >
      {filters?.date?.startDate && (
        <div className="whitespace-nowrap rounded-md border border-[#D7D7D7] py-2.5 px-3 flex gap-1 items-center">
          <span>
            {moment(filters.date.startDate).format("MM/DD/YYYY")} -{" "}
            {moment(filters.date.endDate).format("MM/DD/YYYY")}
          </span>
          <button
            onClick={() => {
              setFilters((prev) => {
                return {
                  ...prev,
                  date: { startDate: "", endDate: "" },
                  selectedDateFilter: null,
                };
              });
              setTempFilters((prev) => {
                return {
                  ...prev,
                  date: { startDate: "", endDate: "" },
                  selectedDateFilter: null,
                };
              });
            }}
          >
            <CloseIcon className="w-4 h-4" />
          </button>
        </div>
      )}
      {filters?.types?.map((type) => (
        <div className="rounded-md whitespace-nowrap border border-[#D7D7D7] py-2.5 px-3 flex gap-1 items-center">
          <span>
            {statusOptions.find((t) => t.value === type)?.label || type}
          </span>
          <button
            onClick={() => {
              setFilters((prev) => {
                return {
                  ...prev,
                  types: prev.types.filter((t) => t !== type),
                };
              });
              setTempFilters((prev) => {
                return {
                  ...prev,
                  types: prev.types.filter((t) => t !== type),
                };
              });
            }}
          >
            <CloseIcon className="w-4 h-4" />
          </button>
        </div>
      ))}
      {hasFilters && (
        <button
          className="hidden md:block text-secondaryRed whitespace-nowrap font-medium"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setFilters({
              date: { startDate: "", endDate: "" },
              types: [],
            });
            setTempFilters({
              date: { startDate: "", endDate: "" },
              types: [],
              selectedDateFilter: null,
            });
          }}
        >
          Clear all filters
        </button>
      )}
    </div>
  );
}
