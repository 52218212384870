import clsx from "clsx";
import { ReactComponent as BuildingIcon } from "../assets/icons/buliding.svg";
import { ReactComponent as ClockIcon } from "../assets/icons/clock.svg";
import StatusBadge from "./StatusBadge";
import { BusinessEntity } from "../../types/business-entity.types";
import moment from "moment";

export default function EntityCard({
  className,
  entity,
  noBorder,
}: {
  className?: string;
  entity?: BusinessEntity;
  noBorder?: boolean;
}) {
  return (
    <div
      className={clsx(
        "flex flex-row items-center py-4 px-5 gap-4 border-b border-b-[#D7D7D7] justify-between",
        className,
        {
          "border-b-0": noBorder,
        }
      )}
    >
      <div className="flex flex-row gap-4">
        <div className="p-2 rounded-[6px] bg-[#F9F6FF]">
          <BuildingIcon className="[&_g]:fill-[#5A42B8] [&_path]:fill-[#5A42B8]" />
        </div>
        <div className="flex flex-col">
          <span className="text-lg leading-normal font-medium">
            {entity?.name}
          </span>
          <div className="flex flex-row gap-1 items-center">
            <ClockIcon width={18} height={18} />
            <span className="text-sm text-[#666]">Applied on:</span>
            <span className="text-sm font-medium text-[#666]">
              {moment(entity?.createdAt).format("DD MMM, YYYY")}
            </span>
          </div>
        </div>
      </div>
      <StatusBadge
        status={
          entity?.status === 0
            ? "In-progress"
            : entity?.status === 1
            ? "Completed"
            : "Refused"
        }
      />
    </div>
  );
}

export function EntityCardSkeleton({
  className,
  noBorder,
}: {
  className?: string;
  noBorder?: boolean;
}) {
  return (
    <div
      className={clsx(
        "flex flex-row items-center py-4 px-5 gap-4 border-b border-b-[#D7D7D7] justify-between animate-pulse",
        className,
        {
          "border-b-0": noBorder,
        }
      )}
    >
      <div className="flex flex-row gap-4">
        <div className="p-2 rounded-[6px] bg-[#F9F6FF]">
          <BuildingIcon className="[&_g]:fill-[#5A42B8] [&_path]:fill-[#5A42B8]" />
        </div>
        <div className="flex flex-col gap-2.5">
          <span className="text-lg leading-normal font-medium w-36 h-4 bg-gray-300 rounded-md"></span>
          <div className="flex flex-row gap-1 items-center">
            <ClockIcon width={18} height={18} />
            <span className="text-sm text-[#666] w-24 h-3 bg-gray-300 rounded-md"></span>
          </div>
        </div>
      </div>
      <div className="w-24 h-5 bg-gray-300 rounded-md"></div>
    </div>
  );
}
