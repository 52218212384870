import Button from "../../components/Button";
import LayoutImg from "../../assets/images/form-header.svg";
import { Layout } from "../../components/Layout";
import { useState } from "react";
import { api } from "../../helpers/api";
import { setToken, setUser } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    api
      .login(formData)
      .then((res) => {
        const { accessToken, ...userWithoutToken } = res.data;
        setToken(accessToken);
        setUser(userWithoutToken);
        navigate("/dashboard");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      <form
        className="grid p-[1.5rem] rounded-[24px] lg:w-[500px] w-[calc(100vw-2rem)] gap-[1.5rem] items-center h-full justify-center bg-white mx-auto"
        onSubmit={handleLogin}
      >
        <div className="text-center grid gap-3 mb-[0.5rem]">
          <div>
            <img
              src={LayoutImg}
              alt="layout"
              className="w-full mx-auto"
              loading="lazy"
            />
            <h1 className="text-[1.5rem] font-outfit font-[600] leading-[110%]">
              Welcome to <span className="text-primary">Marble Legal</span>
            </h1>
          </div>
          <p className="font-[500] text-[0.875rem] text-[#666] leading-[110%]">
            Sign in to your account
          </p>
          <div className="flex flex-col gap-4 mt-4">
            <input
              name="email"
              type="email"
              placeholder="Enter your email"
              className="border border-[#D7D7D7] bg-white rounded-[10px] px-4 py-3 text-sm leading-[18px]"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            <input
              name="password"
              type="password"
              placeholder="Enter your password"
              className="border border-[#D7D7D7] bg-white rounded-[10px] px-4 py-3 text-sm leading-[18px]"
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
            <Button
              className="w-full"
              type="submit"
              disabled={isLoading || !formData.email || !formData.password}
              loading={isLoading}
            >
              Sign in
            </Button>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default LoginForm;
