import Button from "../../components/Button";
import SearchComponent from "../../components/Search";
import { ReactComponent as DotsIcon } from "../../assets/icons/vertical-dots.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as BuildingIcon } from "../../assets/icons/buliding.svg";
import UIPopover from "../../components/Popover";
import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";
import StatusBadge from "../../components/StatusBadge";
import Filters from "./Filter";
import EntityDetails from "./EntityDetails";
import { ShowToast } from "../../components/toast";
import { TableSkeleton } from "../users/Users";
import ArchivedEntities from "./ArchivedEntities";
import clsx from "clsx";
import { SelectedFilters } from "./SelectedFilters";
import React from "react";

type Entity = {
  id: string;
  name: string;
  submittedBy: string;
  createdAt: string;
  status: number;
};

const columnHelper = createColumnHelper<Entity>();

export default function Entities() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isEntityDetailsOpen, setIsEntityDetailsOpen] = useState(false);
  const [searchKeyword, setSearch] = useState("");
  const [filters, setFilters] = useState<{
    date: { startDate?: string; endDate?: string };
    types?: string[];
    selectedDateFilter?: string;
  }>();
  const queryClient = useQueryClient();

  const [tempFilters, setTempFilters] = React.useState<{
    date: { startDate: string; endDate: string };
    types: string[];
    selectedDateFilter: string;
  }>({
    date: { startDate: "", endDate: "" },
    types: [],
    selectedDateFilter: "",
  });
  const [tableData, setTableData] = useState<Entity[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<any>();
  const [isArchivedEntitiesOpen, setIsArchivedEntitiesOpen] = useState(false);
  const loadMoreRef = useRef<any>(null);

  const fetchEntities = async ({ pageParam }) => {
    const res = await api.getEntities({
      page: pageParam,
      limit: 10,
      searchKeyword: searchKeyword,
      status: filters?.types,
      startDate: filters?.date.startDate,
      endDate: filters?.date.endDate,
    });
    return res;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["entities", searchKeyword, filters],
    queryFn: fetchEntities,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, pageParam) => {
      // console.log(lastPage);
      if (lastPage.length < 10) return null;
      return pageParam + 1;
    },
  });

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => (
        <div className="flex flex-row items-center gap-4">
          <div className="p-2 rounded-[6px] bg-[#F6F3FC]">
            <BuildingIcon className="[&_g]:fill-[#5A42B8] [&_path]:fill-[#5A42B8]" />
          </div>
          <span>{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.submittedBy, {
      header: "Submitted by",
    }),
    columnHelper.accessor("createdAt", {
      header: "Date submitted ",
      cell: (info) => (
        <div className="flex flex-row items-center gap-1">
          <span>{moment(info.getValue()).format("MMM DD, YYYY")}</span>
        </div>
      ),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => (
        <div className="flex flex-row justify-between w-full items-center gap-6">
          <StatusBadge
            status={
              info?.row?.original?.status === 0
                ? "In-progress"
                : info?.row?.original?.status === 1
                ? "Completed"
                : "Refused"
            }
          />
          <UIPopover
            trigger={
              <Button
                variant="outline"
                className="h-[2.5rem] w-[2.5rem] flex !p-2 items-center justify-center"
              >
                <DotsIcon />
              </Button>
            }
          >
            {(close) => (
              <div className="flex flex-col gap-4 bg-white p-4 rounded-lg shadow-[0px_6px_26px_0px_rgba(28,43,40,0.14)] font-inter mr-4 text-sm">
                <button
                  className="text-start"
                  onClick={() => {
                    setIsEntityDetailsOpen(true);
                    setSelectedEntity(info.row.original);
                  }}
                >
                  View
                </button>
                <button
                  className="text-start"
                  onClick={() =>
                    api
                      .updateEntityStatus({
                        status: 1,
                        id: info.row.original.id,
                      })
                      .then(() => {
                        ShowToast({
                          message: "Entity status updated successfully",
                          type: "success",
                        });
                        close();
                        refetch();
                      })
                      .catch((err) => {
                        ShowToast({
                          message:
                            err.response.data.message ||
                            "Failed to update entity status",
                          type: "error",
                        });
                      })
                  }
                >
                  Mark as Completed
                </button>
                <button
                  className="text-start"
                  onClick={() =>
                    api
                      .updateEntityStatus({
                        status: -1,
                        id: info.row.original.id,
                      })
                      .then(() => {
                        ShowToast({
                          message: "Entity status updated successfully",
                          type: "success",
                        });
                        close();
                        refetch();
                      })
                      .catch((err) => {
                        ShowToast({
                          message:
                            err.response.data.message ||
                            "Failed to update entity status",
                          type: "error",
                        });
                      })
                  }
                >
                  Refuse
                </button>
                <button
                  className="text-[#F54343] text-start"
                  onClick={() =>
                    api
                      .archiveEntity(info.row.original.id)
                      .then(() => {
                        ShowToast({
                          message: "Entity archived successfully",
                          type: "success",
                        });
                        close();
                        queryClient.invalidateQueries({
                          queryKey: ["entities"],
                        });
                        queryClient.invalidateQueries({
                          queryKey: ["archived-entities"],
                        });
                      })
                      .catch((err) => {
                        ShowToast({
                          message:
                            err.response.data.message ||
                            "Failed to archive entity",
                          type: "error",
                        });
                      })
                  }
                >
                  Archive form
                </button>
              </div>
            )}
          </UIPopover>
        </div>
      ),
    }),
  ];

  const table = useReactTable<Entity>({
    data: tableData || [], // Correctly set the data property
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useMemo(() => {
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap((page) => page);
      // Set the combined data into the table data state
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);
  const hasFilters = useMemo(() => {
    return filters?.date.startDate || (filters?.types?.length || 0) > 0;
  }, [filters]);

  return (
    <div className="flex flex-col gap-4">
      <EntityDetails
        isOpen={isEntityDetailsOpen}
        handleClose={() => setIsEntityDetailsOpen(false)}
        data={selectedEntity}
        refetchEntityDetails={() => {
          queryClient.invalidateQueries({
            queryKey: ["entities"],
          });
          queryClient.invalidateQueries({
            queryKey: ["archived-entities"],
          });
        }}
      />
      <ArchivedEntities
        isOpen={isArchivedEntitiesOpen}
        handleClose={() => setIsArchivedEntitiesOpen(false)}
        setIsEntityDetailsOpen={setIsEntityDetailsOpen}
        setSelectedEntity={setSelectedEntity}
      />

      <div className="flex flex-row justify-between">
        <h1 className="title">Entity forms</h1>
        <Button
          variant="link"
          onClick={() => {
            setIsArchivedEntitiesOpen(true);
          }}
        >
          Archived Forms
        </Button>
      </div>
      <div className="flex flex-row mt-2 mb-1 justify-between items-center">
        <SearchComponent
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          value={searchKeyword}
        />
        <div className="flex flex-row gap-2 items-center">
          <Filters
            setFilters={setFilters}
            tempFilters={tempFilters}
            setTempFilters={setTempFilters}
          />
        </div>
      </div>
      <SelectedFilters
        filters={filters}
        setFilters={setFilters}
        hasFilters={
          filters?.date.startDate || (filters?.types?.length || 0) > 0
        }
        setTempFilters={setTempFilters}
      />
      <div
        className={`overflow-auto ${
          hasFilters ? "h-[calc(100vh-260px)]" : "h-[calc(100vh-190px)]"
        } relative flex flex-col`}
      >
        <table className="w-full relative">
          <thead className="w-full">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="sticky top-0">
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className={clsx(
                      "text-[0.875rem] font-medium text-[#202020] text-start p-0"
                    )}
                  >
                    <div
                      className={clsx(
                        "w-full px-5 py-4 border-t border-b border-[#D7D7D7] bg-white flex flex-row items-center gap-1 transition-all",
                        index === headerGroup.headers.length - 1 &&
                          "border-r rounded-tr-[14px] rounded-br-[14px]",
                        index === 0 &&
                          "border-l rounded-tl-[14px] rounded-bl-[14px]"
                      )}
                      // onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      {/* {{
                        asc: <ArrowIcon />,
                        desc: <ArrowIcon className="rotate-[180deg]" />,
                      }[header.column.getIsSorted() as string] ?? null} */}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {!isLoading &&
              table.getRowModel().rows.map((row, index) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={clsx(
                        "px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]",
                        // last index no border
                        index === table.getRowModel().rows.length - 1 &&
                          "border-b-0"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            {isFetching && (
              <TableSkeleton rows={5} columns={table.getAllColumns().length} />
            )}
          </tbody>
        </table>
        {!isLoading && !isFetching && tableData.length === 0 && (
          <div className="flex-1 flex justify-center items-center">
            <span className="text-[#999999] text-[0.875rem] font-medium">
              No data available
            </span>
          </div>
        )}
        <div className="">
          <button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            ref={loadMoreRef}
          >
            {/* {isFetchingNextPage
              ? "Loading more..."
              : hasNextPage
              ? "Load More"
              : "Nothing more to load"} */}
          </button>
        </div>
        {/* <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div> */}
      </div>
    </div>
  );
}
