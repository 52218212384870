import LogoIcon from "../assets/icons/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import Button from "./Button";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { ReactComponent as SideBarHue } from "../assets/images/sidebar-hue.svg";
import { clearTokenAndUser } from "../helpers/utils";
import menuImage from "../assets/images/sidebar.png";

export default function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    clearTokenAndUser();
    localStorage.clear();
    navigate("/login");
  };

  // Desktop Sidebar
  return (
    <div
      className="lg:flex flex-col h-[100dvh] relative hidden min-w-[300px] p-3 border-r border-[#C2D3C9]"
      style={{
        // position bottom 0 background image
        backgroundImage: `url(${menuImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
      }}
    >
      <SideBarHue className="absolute top-0 left-0 z-0" />
      <div className="border-[1px] border-solid border-[#E5EFF6] p-4 rounded-[12px] bg-[#FDFEFD] mb-5 z-[1]">
        <Link
          to="/home"
          className="flex font-outfit font-[500] text-[1.125rem] items-center gap-1"
        >
          <img src={LogoIcon} alt="logo" className="h-[1.875rem]" />
          {/* <span>Marble Legal</span> */}
        </Link>
      </div>

      <div className="grid gap-[0.375rem] z-[1]">
        <CustomLink to="/dashboard" activePath="/dashboard" label="Dashboard" />
        <CustomLink to="/users" activePath="/users" label="Manage users" />
        <CustomLink
          to="/entities"
          activePath="/entities"
          label="Entity forms"
        />

        <div className="absolute bottom-3 w-[calc(100%-24px)] p-3 border border-[#DDD] rounded-lg flex flex-row items-center gap-4">
          <button onClick={handleLogout}>
            <LogoutIcon width={24} height={24} />
          </button>
          <span className="font-medium leading-[110%]">Admin</span>
        </div>
      </div>
    </div>
  );
}

function CustomLink({
  to,
  activePath,
  label,
}: {
  to: string;
  activePath: string;
  label: string;
}) {
  const isActive = window.location.pathname === activePath;

  return (
    <Link to={to}>
      <Button
        className={clsx(
          "!px-[1.375rem] py-3.5 flex flex-row !justify-start gap-2 w-full items-center border-[1px] border-transparent",
          {
            "!bg-[#E5F5EA] border-[1px] border-solid !border-[#C1D2C5]":
              isActive,
          }
        )}
        variant={isActive ? "primary" : "ghost"}
      >
        <span
          className={clsx(
            { "!text-[#666]": !isActive },
            "text-black font-[500] text-[1rem]"
          )}
        >
          {label}
        </span>
      </Button>
    </Link>
  );
}
