export const subscriptions = [
  {
    id: "CU",
    name: "Pay per query",
    bg: "#D5EFFA",
    color: "#139EA7",
    border: "#139EA726",
  },
  {
    id: "IN",
    name: "Base",
    bg: "#DECAFF",
    color: "#883EC2",
    border: "#883EC203",
  },
  {
    id: "SB",
    name: "Professional",
    bg: "#FFE5D6",
    color: "#B85042",
    border: "#B8504226",
  },
  {
    id: "SP",
    name: "Enterprise",
    bg: "#D6FFE1",
    color: "#1B7329",
    border: "#42B85526",
  },
];
