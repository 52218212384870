import clsx from "clsx";
import { subscriptions } from "../helpers/consts";

export default function SubscriptionBadge({
  plan,
  className,
}: {
  plan: string;
  className?: string;
}) {
  const subscription = subscriptions.find((s) => s.id === plan);

  return (
    <div
      className={clsx(
        `flex flex-row items-center gap-2 px-2 py-1 rounded-md border border-solid w-fit`,
        className
      )}
      style={{
        backgroundColor: subscription?.bg,
        color: subscription?.color,
        borderColor: subscription?.border,
      }}
    >
      <span>{subscription?.name}</span>
    </div>
  );
}
