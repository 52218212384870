import Button from "../../components/Button";
import SearchComponent from "../../components/Search";
import { ReactComponent as FilterIcon } from "../../assets/icons/filters.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/vertical-dots.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";
import UserIcon from "../../assets/icons/profile-icon.svg";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SubscriptionBadge from "../../components/SubscriptionBadge";
import UIPopover from "../../components/Popover";
import UserDetails, { ConfirmBlock, ConfirmDelete } from "./UserDetails";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";
import { ShowToast } from "../../components/toast";
import BlockedUsers from "./BlockedUsers";
import clsx from "clsx";
import Filters from "./Filter";
import { SelectedFilters } from "./SelectedFilters";

type Person = {
  id: string;
  fullName: string;
  email: string;
  joinedOn: string;
  tier: string;
  lastActive: string;
  profileImg: string;
  isActive: boolean;
};

const columnHelper = createColumnHelper<Person>();

export default function Users() {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [isUserDetailsOpen, setIsUserDetailsOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<Person | null>(null);
  const [search, setSearch] = React.useState("");
  const [tableData, setTableData] = React.useState<Person[]>([]);
  const [isBlockedUsers, setIsBlockedUsers] = React.useState(false);
  const loadMoreRef = useRef<any>(null);
  const [filters, setFilters] = useState<{
    date: { startDate?: string; endDate?: string };
    types?: string[];
    selectedDateFilter?: string;
  }>();
  const [tempFilters, setTempFilters] = React.useState<{
    date: { startDate: string; endDate: string };
    types: string[];
    selectedDateFilter: string;
  }>({
    date: { startDate: "", endDate: "" },
    types: [],
    selectedDateFilter: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const fetchUsers = async ({ pageParam }) => {
    const res = await api.getUsers({
      page: pageParam,
      limit: 10,
      search: search,
      filters,
    });
    return res;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["users", search, filters],
    queryFn: fetchUsers,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, pageParam) => {
      // console.log(lastPage);
      if (lastPage.length < 10) return null;
      return pageParam + 1;
    },
  });

  const columns = [
    columnHelper.accessor("fullName", {
      header: "Full name",
      cell: (info) => (
        <div className="flex flex-row items-center gap-2.5">
          <img
            src={info.row.original.profileImg || UserIcon}
            alt="user"
            className="w-10 h-10 rounded-full object-cover"
          />

          <span>{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.email, {
      header: "Email",
    }),
    columnHelper.accessor("joinedOn", {
      header: "Joined on",
      cell: (info) => moment(info.getValue()).format("MMM DD, YYYY") || "-",
    }),
    columnHelper.accessor("tier", {
      header: "Plan",
      cell: (info) =>
        !info.getValue() ? "-" : <SubscriptionBadge plan={info.getValue()} />,
    }),
    columnHelper.accessor("lastActive", {
      header: "Last activity",
      cell: (info) => (
        <div className="flex flex-row justify-between w-full items-center">
          {info.getValue()
            ? moment(info.getValue()).local().format("MMM DD, YYYY | hh:mmA")
            : "-"}
          <UIPopover
            trigger={
              <Button
                variant="outline"
                className="h-[2.5rem] w-[2.5rem] flex !p-2 items-center justify-center"
              >
                <DotsIcon />
              </Button>
            }
          >
            {(close) => (
              <div className="flex flex-col gap-4 bg-white p-4 rounded-lg shadow-[0px_6px_26px_0px_rgba(28,43,40,0.14)] font-inter mr-4 text-sm">
                <button
                  className="text-start"
                  onClick={() => {
                    setSelectedUser(info.row.original);
                    setIsUserDetailsOpen(true);
                  }}
                >
                  View profile
                </button>
                <button
                  className="text-start"
                  onClick={() => {
                    setIsBlockModalOpen(true);
                    setSelectedUser(info.row.original);
                  }}
                >
                  De-activate user
                </button>
                <button
                  className="text-[#F54343] text-start"
                  onClick={() => {
                    setIsDeleteModalOpen(true);
                    setSelectedUser(info.row.original);
                  }}
                >
                  Delete user
                </button>
              </div>
            )}
          </UIPopover>
        </div>
      ),
    }),
  ];

  const table = useReactTable<Person>({
    data: tableData || [], // Correctly set the data property
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useMemo(() => {
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap((page) => page);
      // Set the combined data into the table data state
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const hasFilters = useMemo(() => {
    return filters?.date.startDate || (filters?.types?.length || 0) > 0;
  }, [filters]);

  return (
    <div className="flex flex-col gap-4">
      <UserDetails
        isOpen={isUserDetailsOpen}
        setIsOpen={setIsUserDetailsOpen}
        userData={selectedUser!}
        refetchAllUsers={refetch}
      />
      <BlockedUsers
        isOpen={isBlockedUsers}
        handleClose={() => setIsBlockedUsers(false)}
        setSelectedUser={setSelectedUser}
        setIsUserDetailsOpen={setIsUserDetailsOpen}
        refetchActiveUsers={refetch}
      />
      {isDeleteModalOpen && selectedUser && (
        <ConfirmDelete
          onClose={() => setIsDeleteModalOpen(false)}
          userId={selectedUser.id}
          refetch={() => {
            refetch();
          }}
        />
      )}
      {isBlockModalOpen && selectedUser && (
        <ConfirmBlock
          onClose={() => setIsBlockModalOpen(false)}
          userId={selectedUser.id}
          refetch={() => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            queryClient.invalidateQueries({ queryKey: ["blocked-users"] });
          }}
          isActive={true}
        />
      )}

      <div className="flex flex-row justify-between">
        <h1 className="title">Manage users</h1>
        <Button
          variant="link"
          onClick={() => setIsBlockedUsers(!isBlockedUsers)}
        >
          Blocked Users
        </Button>
      </div>
      <div className="flex flex-row mt-2 mb-1 justify-between items-center">
        <SearchComponent
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Search"
          value={search}
        />
        <div className="flex flex-row gap-2 items-center">
          <Filters
            setFilters={setFilters}
            tempFilters={tempFilters}
            setTempFilters={setTempFilters}
          />
        </div>
      </div>
      <SelectedFilters
        filters={filters}
        setFilters={setFilters}
        hasFilters={
          filters?.date.startDate || (filters?.types?.length || 0) > 0
        }
        setTempFilters={setTempFilters}
      />

      <div
        className={`overflow-auto ${
          hasFilters ? "h-[calc(100vh-260px)]" : "h-[calc(100vh-190px)]"
        } relative flex flex-col`}
      >
        <table className="w-full relative">
          <thead className="w-full">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="sticky top-0">
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className={clsx(
                      "text-[0.875rem] font-medium text-[#202020] text-start p-0"
                    )}
                  >
                    <div
                      className={clsx(
                        "w-full px-5 py-4 border-t border-b border-[#D7D7D7] bg-white flex flex-row items-center gap-1 transition-all",
                        index === headerGroup.headers.length - 1 &&
                          "border-r rounded-tr-[14px] rounded-br-[14px]",
                        index === 0 &&
                          "border-l rounded-tl-[14px] rounded-bl-[14px]"
                      )}
                      // onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      {/* {{
                        asc: <ArrowIcon />,
                        desc: <ArrowIcon className="rotate-[180deg]" />,
                      }[header.column.getIsSorted() as string] ?? null} */}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {!isLoading &&
              table.getRowModel().rows.map((row, index) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={clsx(
                        "px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]",
                        // last index no border
                        index === table.getRowModel().rows.length - 1 &&
                          "border-b-0"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}

            {isFetching && (
              <TableSkeleton rows={5} columns={table.getAllColumns().length} />
            )}
          </tbody>
        </table>
        {!isLoading && !isFetching && tableData.length === 0 && (
          <div className="flex-1 flex justify-center items-center">
            <span className="text-[#999999] text-[0.875rem] font-medium">
              No data available
            </span>
          </div>
        )}
        <div className="">
          <button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            ref={loadMoreRef}
          >
            {/* {isFetchingNextPage
              ? "Loading more..."
              : hasNextPage
              ? "Load More"
              : "Nothing more to load"} */}
          </button>
        </div>
        {/* <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div> */}
      </div>
    </div>
  );
}

export function TableSkeleton({
  rows,
  columns,
}: {
  rows: number;
  columns: number;
}) {
  return (
    <>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <tr key={`skeleton-row-${rowIndex}`} className="animate-pulse">
          {Array.from({ length: columns }).map((_, colIndex) => (
            <td
              key={`skeleton-cell-${rowIndex}-${colIndex}`}
              className="px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]"
            >
              <div className="w-full h-4 bg-gray-300 rounded-md"></div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
