import EntityCard, { EntityCardSkeleton } from "../../components/EntityCard";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import { Link } from "react-router-dom";
import { BusinessEntity } from "../../../types/business-entity.types";
import EntityDetails from "../entities/EntityDetails";
import { useState } from "react";

export default function RecentEntities() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<any>();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["entities"],
    queryFn: () =>
      api.getEntities({
        page: 0,
        limit: 10,
      }),
  });

  return (
    <div className="dashboard-card h-[75dvh] flex flex-col !p-0 !gap-0">
      <EntityDetails
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
        data={selectedEntity}
        refetchEntityDetails={refetch}
      />
      <div className="flex flex-row justify-between items-center border-b border-b-[#D7D7D7] p-6">
        <span className="text-xl leading-[113.6%] font-bold">
          Recent Entity forms
        </span>
        <Link
          to="/entities"
          className="px-4 py-2 rounded-[20px] bg-[#F3F3F3] text-[1rem] leading-[113.6%] flex flex-row gap-1 items-center h-fit"
        >
          All forms
        </Link>
      </div>
      {!isLoading && (
        <div className="flex flex-col overflow-auto">
          {data?.map((entity: BusinessEntity) => (
            <button
              onClick={() => {
                setIsOpen(true);
                setSelectedEntity(entity);
              }}
              className="text-start"
            >
              <EntityCard
                key={entity.id}
                entity={entity}
                noBorder={data[data.length - 1].id === entity.id}
              />
            </button>
          ))}
        </div>
      )}
      {isLoading && (
        <div className="flex flex-col overflow-auto">
          <EntityCardSkeleton />
          <EntityCardSkeleton />
          <EntityCardSkeleton />
        </div>
      )}
    </div>
  );
}
