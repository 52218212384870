import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as MoneyIcon } from "../../assets/icons/money.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import { useAuth } from "../../contexts/AuthContext";
import Chart from "./Chart";
import RecentEntities from "./Entities";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const { user } = useAuth();
  const { data, isLoading } = useQuery({
    queryKey: ["dashboard", user?.id],
    queryFn: () => api.dashboard(user!.id),
    // enabled: !!user,
  });

  return (
    <div className="flex flex-col gap-4">
      <h1 className="title">Dashboard</h1>
      {!isLoading && (
        <div className="flex flex-row gap-[1.125rem] flex-wrap">
          <div className="dashboard-card">
            <UsersIcon />
            <Link to="/users" className="flex flex-col gap-3.5">
              <span className="font-medium leading-[113.6%]">Total Users</span>
              <span className="font-bold text-2xl text-[#101828]">
                {data?.totalUsers}
              </span>
            </Link>
          </div>
          <div className="dashboard-card">
            <MoneyIcon />
            <div className="flex flex-col gap-3.5">
              <span className="font-medium leading-[113.6%]">
                Total Revenue
              </span>
              <span className="font-bold text-2xl text-[#101828]">
                {data?.totalRevenue}
              </span>
            </div>
          </div>
          <div className="dashboard-card">
            <CalendarIcon />
            <div className="flex flex-col gap-3.5">
              <span className="font-medium leading-[113.6%]">
                # of active subscriptions
              </span>
              <span className="font-bold text-2xl text-[#101828]">
                {data?.totalSubscriptions}
              </span>
            </div>
          </div>
        </div>
      )}
      {isLoading && <LoadingSkeleton />}

      <div className="flex flex-row gap-[1.125rem] flex-wrap">
        <Chart />
        <RecentEntities />
      </div>
    </div>
  );
}

function LoadingSkeleton() {
  return (
    <div className="flex flex-row gap-[1.125rem] flex-wrap animate-pulse">
      <div className="dashboard-card">
        <UsersIcon />
        <div className="flex flex-col gap-3.5">
          <span className="font-medium leading-[113.6%]">Total Users</span>
          <div className="font-bold text-2xl text-[#101828] w-24 h-4 bg-gray-300 rounded-md"></div>
        </div>
      </div>
      <div className="dashboard-card">
        <MoneyIcon />
        <div className="flex flex-col gap-3.5">
          <span className="font-medium leading-[113.6%]">Total Revenue</span>
          <div className="font-bold text-2xl text-[#101828] w-24 h-4 bg-gray-300 rounded-md"></div>
        </div>
      </div>
      <div className="dashboard-card">
        <CalendarIcon />
        <div className="flex flex-col gap-3.5">
          <span className="font-medium leading-[113.6%]">
            # of active subscriptions
          </span>
          <div className="font-bold text-2xl text-[#101828] w-24 h-4 bg-gray-300 rounded-md"></div>
        </div>
      </div>
    </div>
  );
}
