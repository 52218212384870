import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as SlashIcon } from "../../assets/icons/slash.svg";
import UserIcon from "../../assets/icons/profile-icon.svg";
import FullScreenModal from "../../components/FullScreenModal";
import SubscriptionBadge from "../../components/SubscriptionBadge";
import EntityCard, { EntityCardSkeleton } from "../../components/EntityCard";
import { PopupModal } from "../../components/PopupModal";
import Button from "../../components/Button";
import { useState } from "react";
import moment from "moment";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { BusinessEntity } from "../../../types/business-entity.types";
import { api } from "../../helpers/api";
import { ShowToast } from "../../components/toast";

export default function UserDetails({
  isOpen,
  setIsOpen,
  userData,
  refetchAllUsers,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userData: {
    id: string;
    fullName: string;
    email: string;
    joinedOn: string;
    tier: string;
    lastActive: string;
    profileImg: string;
    isActive: boolean;
  };
  refetchAllUsers: () => void;
}) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);

  const fetchBlockedUsers = async ({ pageParam }) => {
    const res = await api.getUsers({
      page: pageParam,
      limit: 10,
      showActive: false,
    });
    return res;
  };

  const { data, isLoading } = useQuery({
    queryKey: ["user-entity", userData?.id],
    queryFn: () => api.getEntities({ id: userData?.id, page: 0, limit: 20 }),
    enabled: !!userData?.id && isOpen,
  });
  const {
    data: userApiData,
    isLoading: isUserInfoLoading,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ["user-details", userData?.id],
    queryFn: () => api.getUser({ id: userData?.id }),
    enabled: !!userData?.id && isOpen,
  });
  const { refetch: refetchBlockedUsers } = useInfiniteQuery({
    queryKey: ["blocked-users", isOpen],
    queryFn: fetchBlockedUsers,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, pageParam) => {
      // console.log(lastPage);
      if (lastPage.length < 10) return null;
      return pageParam + 1;
    },
  });

  return (
    <FullScreenModal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      customZIndex={100}
    >
      {isDeleteModalOpen && (
        <ConfirmDelete
          onClose={() => setIsDeleteModalOpen(false)}
          userId={userData?.id}
          refetch={() => {
            refetchUser();
            refetchAllUsers();
            refetchBlockedUsers();
            setIsOpen(false);
          }}
        />
      )}
      {isBlockModalOpen && (
        <ConfirmBlock
          onClose={() => setIsBlockModalOpen(false)}
          userId={userData?.id}
          refetch={() => {
            refetchUser();
            refetchAllUsers();
            refetchBlockedUsers();
            setIsOpen(false);
          }}
          isActive={userApiData?.data?.isActive}
        />
      )}
      <div className="max-w-[900px] mx-auto py-5">
        <div className="flex flex-row justify-end gap-6 mb-5 w-full">
          {/* <button className="flex flex-row gap-1 items-center ml-auto">
            <EditIcon />
            Edit profile
          </button> */}
          {!isUserInfoLoading && (
            <button
              className="flex flex-row gap-1 items-center ml-auto"
              onClick={() => setIsBlockModalOpen(true)}
            >
              <SlashIcon />
              {userApiData?.data?.isActive ? "Block user" : "Unblock user"}
            </button>
          )}
          <button
            className="text-[#F54343] flex flex-row gap-1 items-center"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <DeleteIcon />
            Remove user
          </button>
        </div>
        <div className="bg-white rounded-[16px] shadow-[0px_4px_14px_0px_rgba(0,0,0,0.03)] p-6 flex flex-row gap-3.5 items-center mb-5">
          <img
            src={userData?.profileImg || UserIcon}
            alt="user"
            className="w-32 h-32 rounded-full"
          />
          <div className="flex flex-col gap-1.5">
            <span className="font-bold text-[1.125rem]">
              {userData?.fullName}
            </span>
            <span className="text-[#686868] text-[0.9375rem]">
              Email address:{" "}
              <span className="text-[#202020] font-medium">
                {userData?.email}
              </span>
            </span>
            {userData?.tier ? (
              <SubscriptionBadge
                plan={userData?.tier}
                className="px-2.5 py-1.5 text-xs text-[0.8125rem]"
              />
            ) : null}
          </div>
          <div className="flex flex-col ml-auto">
            <span className="text-[#777] text-[0.875rem] ml-auto">
              Joined on:
            </span>
            <span className="text-[#202020] text-[0.875rem]">
              {moment(userData?.joinedOn).format("MMM DD, YYYY")}
            </span>
          </div>
        </div>
        <div className="flex flex-row gap-10 mb-[1.5rem]">
          <div className="flex flex-row gap-2">
            <span className="text-[#777] text-[0.875rem]">Last activity:</span>
            <span className="text-[#202020] text-[0.875rem]">
              {userData?.lastActive
                ? moment(userData?.lastActive)
                    .local()
                    .format("MMM DD, YYYY | hh:mmA")
                : "-"}
            </span>
          </div>
          <div className="flex flex-row gap-2">
            <span className="text-[#777] text-[0.875rem]">
              Contract draft generated
            </span>
            <span className="text-[#202020] text-[0.875rem]">
              {userApiData?.data?.totalDrafts}
            </span>
          </div>
          <div className="flex flex-row gap-2">
            <span className="text-[#777] text-[0.875rem]">Analysis done</span>
            <span className="text-[#202020] text-[0.875rem]">
              {userApiData?.data?.totalAnalysis}
            </span>
          </div>
        </div>
        <div className="bg-white rounded-[16px] shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] flex flex-col px-6 pt-6">
          <span className="text-[#33363F] font-outfit text-[1.5rem] font-bold">
            Submitted Entity forms
          </span>
          <div className="px-5 py-4 bg-[#EEF1EF] rounded-[4px] mt-[1.375rem] text-[0.875rem] text-[#202020] justify-between flex flex-row">
            <span>Name</span>
            <span className="w-28">Status</span>
          </div>
          <div className="h-[40dvh] overflow-auto">
            {!isLoading &&
              data?.map((entity: BusinessEntity) => (
                <EntityCard
                  key={entity.id}
                  entity={entity}
                  noBorder={data[data.length - 1].id === entity.id}
                />
              ))}

            {isLoading && <EntityCardSkeleton />}
            {data?.length === 0 && (
              <div className="w-full text-center flex flex-col h-full items-center justify-center">
                <span>No entities submitted</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}

export function ConfirmDelete({
  onClose,
  userId,
  refetch,
}: {
  onClose: () => void;
  userId: string;
  refetch: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = () => {
    setIsLoading(true);
    api
      .deleteUser(userId)
      .then(() => {
        onClose();
        ShowToast({
          message: "User deleted successfully",
          type: "success",
        });
        refetch && refetch();
        setIsLoading(false);
      })
      .catch(() => {
        ShowToast({
          message: "Failed to delete user",
          type: "error",
        });
        setIsLoading(false);
      });
  };

  return (
    <PopupModal onClose={onClose} contentClassName="w-[500px] mx-auto">
      <p>
        Are you sure you want to delete this user? This action cannot be undone.
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button variant="outline" className="px-8" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="px-8 text-[1rem]"
          onClick={handleDelete}
          loading={isLoading}
        >
          Delete
        </Button>
      </div>
    </PopupModal>
  );
}

export function ConfirmBlock({
  onClose,
  userId,
  refetch,
  isActive,
}: {
  onClose: () => void;
  userId: string;
  refetch: () => void;
  isActive: boolean;
}) {
  const [blocking, setBlocking] = useState(false);
  const handleBlock = () => {
    setBlocking(true);
    api
      .updateUser(userId, isActive ? false : true)
      .then(() => {
        setBlocking(false);
        onClose();
        ShowToast({
          message: isActive
            ? "User blocked successfully"
            : "User unblocked successfully",
          type: "success",
        });
        refetch && refetch();
      })
      .catch((err) => {
        setBlocking(false);
        ShowToast({
          message:
            err.response.data.message ||
            `Failed to ${isActive ? "block" : "unblock"} user`,
          type: "error",
        });
      });
  };

  console.log(isActive, "isActive");

  return (
    <PopupModal onClose={onClose} contentClassName="w-[500px] mx-auto">
      <p>
        Are you sure you want to {isActive ? "block" : "unblock"} this user?
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button
          variant="outline"
          className="px-8"
          onClick={onClose}
          disabled={blocking}
        >
          Cancel
        </Button>
        <Button
          className="px-8 text-[1rem]"
          onClick={handleBlock}
          loading={blocking}
        >
          {isActive ? "Block" : "Unblock"}
        </Button>
      </div>
    </PopupModal>
  );
}
