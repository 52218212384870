import Button from "../../components/Button";
import { ReactComponent as DotsIcon } from "../../assets/icons/vertical-dots.svg";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as BuildingIcon } from "../../assets/icons/buliding.svg";
import UIPopover from "../../components/Popover";
import { useInfiniteQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";
import StatusBadge from "../../components/StatusBadge";
import { TableSkeleton } from "../users/Users";
import FullScreenModal from "../../components/FullScreenModal";
import clsx from "clsx";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";

type Entity = {
  id: string;
  name: string;
  submittedBy: string;
  createdAt: string;
  status: number;
};

const columnHelper = createColumnHelper<Entity>();

export default function ArchivedEntities({
  isOpen,
  handleClose,
  setSelectedEntity,
  setIsEntityDetailsOpen,
}: {
  isOpen: boolean;
  handleClose: () => void;
  setIsEntityDetailsOpen: (value: boolean) => void;
  setSelectedEntity: (value: any) => void;
}) {
  const [tableData, setTableData] = useState<Entity[] | null>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const loadMoreRef = useRef<any>(null);

  const fetchEntities = async ({ pageParam }) => {
    console.log(pageParam);
    const res = await api.getEntities({
      page: pageParam,
      limit: 10,
      showActive: false,
    });
    return res;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["archived-entities", isOpen],
    queryFn: fetchEntities,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages, pageParam) => {
      // console.log(lastPage);
      if (lastPage.length < 10) return null;
      return pageParam + 1;
    },
  });

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => (
        <div className="flex flex-row items-center gap-4">
          <div className="p-2 rounded-[6px] bg-[#F6F3FC]">
            <BuildingIcon className="[&_g]:fill-[#5A42B8] [&_path]:fill-[#5A42B8]" />
          </div>
          <span>{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.submittedBy, {
      header: "Submitted by",
    }),
    columnHelper.accessor("createdAt", {
      header: "Date submitted ",
      cell: (info) => (
        <div className="flex flex-row items-center gap-1">
          <span>{moment(info.getValue()).format("MMM DD, YYYY")}</span>
        </div>
      ),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => (
        <div className="flex flex-row justify-between w-full items-center gap-6">
          <StatusBadge status={"Archived"} />
          <UIPopover
            trigger={
              <Button
                variant="outline"
                className="h-[2.5rem] w-[2.5rem] flex !p-2 items-center justify-center"
              >
                <DotsIcon />
              </Button>
            }
          >
            {(close) => (
              <div className="flex flex-col gap-4 bg-white p-4 rounded-lg shadow-[0px_6px_26px_0px_rgba(28,43,40,0.14)] font-inter mr-4 text-sm">
                <button
                  className="text-start"
                  onClick={() => {
                    setIsEntityDetailsOpen(true);
                    setSelectedEntity(info.row.original);
                  }}
                >
                  View
                </button>
              </div>
            )}
          </UIPopover>
        </div>
      ),
    }),
  ];

  const table = useReactTable<Entity>({
    data: tableData || [], // Correctly set the data property
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useMemo(() => {
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap((page) => page);
      // Set the combined data into the table data state
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <FullScreenModal isOpen={isOpen} onClose={handleClose}>
      <div className="overflow-auto h-full relative max-w-[1000px] mx-auto">
        <h1 className="text-2xl font-inter font-medium mb-5 mt-5">
          Archived Entity forms
        </h1>
        <div className="!h-[calc(100%-120px)] overflow-auto">
          <table className="w-full relative">
            <thead className="w-full">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="sticky top-0">
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={clsx(
                        "text-[0.875rem] font-medium text-[#202020] text-start p-0 cursor-pointer"
                      )}
                    >
                      <div
                        className={clsx(
                          "w-full px-5 py-4 border-t border-b border-[#D7D7D7] bg-white flex flex-row items-center gap-1 transition-all",
                          index === headerGroup.headers.length - 1 &&
                            "border-r rounded-tr-[14px] rounded-br-[14px]",
                          index === 0 &&
                            "border-l rounded-tl-[14px] rounded-bl-[14px]"
                        )}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {{
                          asc: <ArrowIcon />,
                          desc: <ArrowIcon className="rotate-[180deg]" />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {!isLoading &&
                table.getRowModel().rows.map((row, index) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={clsx(
                          "px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]",
                          // last index no border
                          index === table.getRowModel().rows.length - 1 &&
                            "border-b-0"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              {isFetching && (
                <TableSkeleton
                  rows={5}
                  columns={table.getAllColumns().length}
                />
              )}
            </tbody>
          </table>
        </div>

        <div className="w-full text-center">
          <button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            ref={loadMoreRef}
            className="mt-6"
          >
            {/* {isFetchingNextPage
              ? "Loading more..."
              : hasNextPage
              ? "Load More"
              : "Nothing more to load"} */}
          </button>
        </div>
        {/* <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div> */}
      </div>
    </FullScreenModal>
  );
}
