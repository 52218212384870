import client from "./client";

export const api = {
  login: (data: any) => {
    return client.post("/auth/login", {
      ...data,
      userType: "A",
    });
  },
  dashboard: (id: string) => {
    return client.get(`/users/${id}/dashboard`).then((res) => res.data);
  },
  getUser: ({ id }: { id: string }) => {
    return client.get(`/users/${id}`);
  },
  // /users/{id}/reports
  getUserRevenue: ({
    id,
    type,
    duration,
  }: {
    id: string;
    type: "USERS_COUNT" | "REVENUE";
    duration: "CURRENT_WEEK" | "CURRENT_MONTH" | "CURRENT_YEAR";
  }) => {
    return client
      .get(`/users/${id}/reports`, {
        params: {
          type,
          duration,
        },
      })
      .then((res) => res.data);
  },
  getEntities: ({
    id,
    page,
    limit,
    searchKeyword,
    status,
    startDate,
    endDate,
    showActive = true,
  }: {
    id?: string;
    page?: number;
    limit?: number;
    searchKeyword?: string;
    status?: string[];
    startDate?: string;
    endDate?: string;
    showActive?: boolean;
  }) => {
    let params = {
      page,
      limit,
      showActive,
    };

    if (searchKeyword) params["searchKeyword"] = searchKeyword;
    if (startDate) params["startDate"] = startDate;
    if (endDate) params["endDate"] = endDate;
    if (id) params["userId"] = id;
    if (status && status?.length > 0) params["status"] = status.join(",");

    // Serialize params to query string
    const queryString = Object.entries(params)
      .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    return client
      .get(`/business-entities?${queryString}`)
      .then((res) => res.data);
  },
  getUsers: ({
    page,
    limit,
    search,
    showActive = true,
    filters,
  }: {
    page: number;
    limit: number;
    search?: string;
    showActive?: boolean;
    filters?: any;
  }) => {
    console.log(filters);
    let url = `/users?page=${page}&limit=${limit}&showActive=${showActive}`;
    if (search) {
      url += `&searchKeyword=${search}`;
    }
    if (filters?.date?.startDate && filters?.date?.endDate) {
      url += `&startDate=${filters?.date?.startDate}&endDate=${filters?.date?.endDate}`;
    }
    if (filters?.types && filters?.types?.length > 0) {
      url += `&tiers=${filters.types.join(",")}`;
    }
    return client.get(url).then((res) => res.data);
  },
  updateUser: (id: string, isActive: boolean) => {
    return client.patch(`/users/${id}/status`, {
      isActive,
    });
  },
  deleteUser: (id: string) => {
    return client.delete(`/users/${id}`);
  },
  getEntity: (id: string) => {
    return client.get(`/business-entities/${id}`).then((res) => res.data);
  },
  updateEntityStatus: ({ status, id }: { status: number; id: string }) => {
    return client.patch(`/business-entities/${id}`, { status });
  },
  archiveEntity: (id: string) => {
    return client.delete(`/business-entities/${id}`);
  },
};
