import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { AuthProvider } from "../contexts/AuthContext";

function MainApp() {
  return (
    <div className="flex-1 flex flex-col lg:flex-row">
      <Sidebar />
      <div className="flex-1 max-h-[100dvh] overflow-auto bg-[#F9F9F9] lg:p-6">
        <Outlet />
      </div>
    </div>
  );
}

export function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", {
        state: { from: `${location.pathname}${location.search}` },
      });
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      if (location.pathname === "/") {
        navigate("/dashboard");
      }
    }
  }, [location]);

  if (!isLoggedIn) return null;
  // if (isLoading) return null;

  return (
    <AuthProvider>
      <MainApp />
    </AuthProvider>
  );
}
