import { useQuery } from "@tanstack/react-query";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { api } from "../helpers/api";
import { getUser } from "../helpers/utils";

// Create a new context
const AuthContext = createContext<any>({});

// Custom hook to access the auth context
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap your app and provide auth context
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const userId = getUser().id;
  const { data: userData, refetch } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => api.getUser({ id: userId }),
    enabled: !!userId,
  });

  useMemo(() => {
    if (userData) {
      setUser(userData.data);
    }
  }, [userData]);

  // Value provided by the context
  const value = {
    user,
    refetch,
  };

  // Render children with auth context provider
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
