import { ReactComponent as UpIcon } from "../../assets/icons/up.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron.svg";
import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import UIPopover from "../../components/Popover";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import { useAuth } from "../../contexts/AuthContext";
import { useState } from "react";
import moment from "moment";
import clsx from "clsx";

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col gap-1 items-center justify-center px-4 py-5 !shadow-[0px_16px_40px_0px_rgba(2,96,0,0.11)] rounded-[11px] bg-white">
        <p className="leading-[113.6%] font-[400] text-[1rem]">
          {payload[0]?.payload?.day ||
            moment()
              .month(payload[0]?.payload?.month - 1)
              .format("MMM")}
        </p>
        <p className="text-[1.375rem] font-semibold leading-[113.6%]">
          {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};
export default function Chart() {
  const [type, setType] = useState<"REVENUE" | "USERS_COUNT">("REVENUE");
  const [duration, setDuration] = useState<
    "CURRENT_WEEK" | "CURRENT_MONTH" | "CURRENT_YEAR"
  >("CURRENT_WEEK");
  const { user } = useAuth();

  const { data, isLoading } = useQuery({
    queryKey: ["chart", user?.id, type, duration],
    queryFn: () =>
      api.getUserRevenue({
        id: user!.id,
        type: type,
        duration: duration,
      }),
    enabled: !!user,
  });

  return (
    <div className="dashboard-card h-[75dvh] flex-col">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-[1.25rem]">
          <button
            className={clsx("text-xl leading-[113.6%] font-bold", {
              "opacity-40": type !== "REVENUE",
            })}
            onClick={() => setType("REVENUE")}
          >
            Revenue
          </button>
          <button
            className={clsx("text-xl leading-[113.6%] font-bold", {
              "opacity-40": type !== "USERS_COUNT",
            })}
            onClick={() => setType("USERS_COUNT")}
          >
            Users
          </button>
        </div>
        <UIPopover
          trigger={
            <div className="px-4 py-2 rounded-[20px] bg-[#F3F3F3] text-[1rem] leading-[113.6%] flex flex-row gap-1 items-center">
              {duration === "CURRENT_WEEK" && "This Week"}
              {duration === "CURRENT_MONTH" && "This Month"}
              {duration === "CURRENT_YEAR" && "This Year"}
              <ChevronIcon className="rotate-[90deg] [&_path]:stroke-black" />
            </div>
          }
        >
          {(close) => (
            <div className="flex flex-col gap-4 bg-white p-4 shadow-lg rounded-lg">
              <button
                onClick={() => {
                  close();
                  setDuration("CURRENT_WEEK");
                }}
                className="text-start font-inter"
              >
                This Week
              </button>
              <button
                onClick={() => {
                  close();
                  setDuration("CURRENT_MONTH");
                }}
                className="text-start font-inter"
              >
                This Month
              </button>
              <button
                onClick={() => {
                  close();
                  setDuration("CURRENT_YEAR");
                }}
                className="text-start font-inter"
              >
                This Year
              </button>
            </div>
          )}
        </UIPopover>
      </div>
      <div className="flex flex-row justify-between rounded-[8px] border border-[#E8E8E8] p-4 my-1 mb-8">
        <span>
          {moment(data?.revenue?.[0]?.day || data?.users?.[0]?.day).format(
            "MMM DD"
          )}{" "}
          -{" "}
          {moment(
            data?.revenue?.[data?.revenue?.length - 1].day ||
              data?.users?.[data?.users?.length - 1].day
          ).format("MMM DD")}
        </span>

        <div className="flex flex-row gap-1 items-center">
          <span>
            {type === "REVENUE" ? "$" : ""}
            {data?.revenue?.reduce(
              (acc: number, curr: any) => acc + curr.count,
              0
            ) ||
              data?.users?.reduce(
                (acc: number, curr: any) => acc + curr.count,
                0
              ) ||
              0}
          </span>
          <UpIcon />
        </div>
      </div>
      {!isLoading && (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={type === "REVENUE" ? data?.revenue : data?.users}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient
                id="paint0_linear_53_758"
                x1="260.598"
                y1="-64.3191"
                x2="271.53"
                y2="378.817"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#4AA064" stopOpacity="1" />
                <stop offset="1" stop-color="#4aa06426" stop-opacity="0" />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              className="text-xs font-[400]"
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              tickFormatter={(tick) => (tick === -10 ? "" : tick)} // Hide the tick at -100
              className="font-[500] text-xs"
              axisLine={false}
              tickLine={false}
              domain={[0, "dataMax"]}
              tickCount={10}
            />
            <Tooltip content={<CustomTooltip />} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="count"
              stroke="#4AA064"
              strokeWidth="3px"
              fill="url(#paint0_linear_53_758)"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
